import React, { useState, useCallback, useRef, useEffect } from "react";
import "./layout.css"; // Import a CSS file for custom styling
import ApiService from "../helpers/Services.js";
import Navbar from "./Navbar.js";
import DocMetadata from "./DocMetadata.js";
import DocumentViewer from "./DocumentViewer.js";
import { toast } from "react-toastify";
import FormBlock from "./FormBlock.js";
import Loader from "./Loader.js";
import DocumentDisplay from "./DocumentDisplay.js";
import { useLocation, useSearchParams } from "react-router-dom";
import storage from "../helpers/storage.js";

const Layout = ({ token }) => {
  const location = useLocation();
  let [searchParams] = useSearchParams();

  const [highlight, setHighlight] = useState({});
  const [doc, setDoc] = useState(null);
  const [loading, setLoading] = useState(false);

  const isPdf = (fileName) => {
    return fileName && fileName.toLowerCase().includes(".pdf");
  };

  const getTask = async (textValue) => {
    setLoading(true);
    setHighlight({});
    setDoc(null);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = await ApiService.post(
        "assign-task",
        { clientItemId: (textValue && textValue.trim()) || "" },
        headers
      );
      setLoading(false);
      setDoc(data);

      // Append the query parameter to the current URL
      storage.setFileName(data?.clientItemId);
      const newUrl = `${location.pathname}?filename=${data?.clientItemId}`;
      window.history.pushState({}, "", newUrl);
      //toast.success("Document Fetched Successfully!", {
      //  position: "top-right",
      //});
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      console.error(error);
    }
  };

  useEffect(() => {
    const filename = searchParams?.get("filename");
    const localStorageSearchParam = storage.getFileName();

    if (filename && filename !== localStorageSearchParam) {
      storage.setFileName(filename);
    }
    if (filename || localStorageSearchParam) {
      getTask(filename ?? localStorageSearchParam);
    }
  }, [location]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = doc.signedURL;
    link.download = doc.fileName || "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="layout-container">
      <Navbar getTask={getTask} doc={doc} />
      {(doc && isPdf(doc.signedURL) && <DocMetadata doc={doc} />) ||
        (doc && <DocMetadata doc={doc} />)}
      {loading ? (
        <Loader />
      ) : (
        <div className="content">
          {doc && (
            <FormBlock
              doc={doc}
              setHighlight={setHighlight}
              getTask={getTask}
              token={token}
            />
          )}
          {doc && isPdf(doc.signedURL) ? (
            <DocumentViewer
              doc={doc}
              highlight={highlight}
              highlightImpLabels={(doc.label_geos || []).concat(
                doc.payload.valueGeos
              )}
            />
          ) : (
            doc && (
              <DocumentDisplay
                src={doc?.signedURL}
                setHighlight={setHighlight}
                highlight={highlight}
                highlightImpLabels={(doc.label_geos || []).concat(
                  doc.payload.valueGeos
                )}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Layout;
